// src/components/Header.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png'; // Import the logo image
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons from react-icons

const Header = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Close drawer when a link is clicked
  const handleLinkClick = () => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  return (
    <header className="header">
      <div className="header__container">
        {/* Logo and Company Name */}
        <div className="header__brand">
          <Link to="/" className="header__brand-link">
            <img src={logo} alt="Clean Sweeps Logo" className="header__logo-image" />
            <span className="header__company-name">Clean Sweeps</span>
          </Link>
        </div>

        {/* Hamburger Menu Icon (Visible on Mobile) */}
        <div className="header__menu-icon" onClick={toggleDrawer}>
          {drawerOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* Navigation (Hidden on Mobile) */}
        <nav className={`header__nav ${drawerOpen ? 'header__nav--open' : ''}`}>
          <ul>
            <li className={location.pathname === '/' ? 'active' : ''}>
              <Link to="/" onClick={handleLinkClick}>Pricing</Link>
            </li>
            <li className={location.pathname === '/tos' ? 'active' : ''}>
              <Link to="/tos" onClick={handleLinkClick}>Terms of Service</Link>
            </li>
            <li className={location.pathname === '/policy' ? 'active' : ''}>
              <Link to="/policy" onClick={handleLinkClick}>Privacy Policy</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
