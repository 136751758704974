// src/pages/TermsOfService.js
import React from "react";
import "./TermsOfService.css"; // Optional: Create and import a CSS file for styling

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to the website of Clean Sweeps LLC located at CleanSweeps.app
          (hereinafter “We”, “Us”, “Our” or “Company). We thank You (any visitor
          to Our web site and hereinafter "You" or "Your") for visiting Our site
          and considering Our products and services. Upon registration and
          clicking the button which states: “By checking this box You are
          agreeing to this website’s Terms of Use and Privacy Policy, as well as
          our use of cookies”, You hereby agree to the terms and conditions of
          this Terms of Use (hereinafter “Agreement”) which, along with the
          consideration of the mutual promises You and We make to each other,
          then becomes a binding contract between You and Us. Please do not use
          this Site if You disagree with any part of this Agreement.
        </p>
        <p>
          Our Mobile Terms of Service is located here and is hereby incorporated
          into this Agreement by reference. Please review the Terms of Service
          to understand Our policies.
        </p>
      </section>

      <section>
        <h2>Privacy Policy</h2>
        <p>
          Our Customer Privacy Policy is located here and is hereby incorporated
          into this Agreement by reference. Please review the Privacy Policy to
          understand Our policies.
        </p>
      </section>

      <section>
        <h2>Copyright</h2>
        <p>
          All content included on Our website, such as text, graphics, logos,
          button icons, images, audio clips, and software, is the property of
          Clean Sweeps LLC and is protected by U.S. copyright laws. The
          compilation (meaning the collection, arrangement, and assembly) of all
          content on Our website is Our exclusive property and protected by U.S.
          copyright laws. Any use of such content, including the reproduction,
          modification, distribution, transmission, republication, or display,
          of the content of Our website is strictly prohibited.
        </p>
      </section>

      <section>
        <h2>Warranties</h2>
        <p>
          THE MATERIAL DISPLAYED ON THIS WEBSITE IS PROVIDED WITHOUT ANY
          GUARANTEES, CONDITIONS, OR WARRANTIES AS TO ITS ACCURACY. UNLESS
          EXPRESSLY STATED TO THE CONTRARY TO THE FULLEST EXTENT PERMITTED BY
          LAW WE AND OUR SUPPLIERS, CONTENT PROVIDERS, AND ADVERTISERS HEREBY
          EXPRESSLY EXCLUDE ALL CONDITIONS, WARRANTIES, AND OTHER TERMS WHICH
          MIGHT OTHERWISE BE IMPLIED BY STATUTE, COMMON LAW, OR THE LAW OF
          EQUITY AND SHALL NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING
          BUT WITHOUT LIMITATION TO ANY DIRECT, INDIRECT, SPECIAL,
          CONSEQUENTIAL, PUNITIVE OR INCIDENTAL DAMAGES, OR DAMAGES FOR LOSS OF
          USE, PROFITS, DATA OR OTHER INTANGIBLES, DAMAGE TO GOODWILL OR
          REPUTATION, OR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
          SERVICES, ARISING OUT OF OR RELATED TO THE USE, INABILITY TO USE,
          PERFORMANCE OR FAILURES OF THIS WEBSITE OR THE LINKED WEBSITES AND ANY
          MATERIALS POSTED THEREON, IRRESPECTIVE OF WHETHER SUCH DAMAGES WERE
          FORESEEABLE OR ARISE IN CONTRACT, TORT, EQUITY, RESTITUTION, BY
          STATUTE, AT COMMON LAW OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. THIS DOES NOT AFFECT OUR LIABILITY
          FOR DEATH OR PERSONAL INJURY ARISING FROM OUR NEGLIGENCE, NOR FOR
          FRAUDULENT MISREPRESENTATION, MISREPRESENTATION AS TO A FUNDAMENTAL
          MATTER, OR ANY OTHER LIABILITY WHICH CAN NOT BE EXCLUDED OR LIMITED
          UNDER APPLICABLE LAW.
        </p>
        <p>
          FURTHERMORE, IF A THIRD PARTY SHALL SURREPTITIOUSLY ENTER OUR WEBSITE
          THROUGH THE USE OF A MEMBER’S LOG-IN OR OTHERWISE, THEY WILL HAVE NO
          RIGHT OF STANDING IN ANY ACTION AGAINST US.
        </p>
      </section>

      <section>
        <h2>Limitation of Liability</h2>
        <p>
          TO THE MAXIMUM EXTENT LEGALLY PERMITTED, WHETHER OR NOT WE ARE OR WERE
          AWARE OR ADVISED OF THE POSSIBILITY OF DAMAGES, AND WHETHER OR NOT THE
          LIMITED REMEDIES PROVIDED HEREIN FAIL OF THEIR ESSENTIAL PURPOSE, OUR
          AGGREGATE LIABILITY (WHETHER FOR BREACH OF CONTRACT, TORT OR ANY OTHER
          LEGAL THEORY) SHALL IN NO CIRCUMSTANCES EXCEED THE COST OF OUR
          PRODUCTS YOU ORDERED. FURTHER, UNDER NO CIRCUMSTANCES SHALL WE BE
          LIABLE FOR SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES,
          LOST PROFITS, LOST REVENUE, OR COST OF COVER. OUR PRODUCTS ARE SOLD
          AND DELIVERED TO YOU “AS IS” WITH NO WARRANTY WHATSOEVER. EXCEPT AS
          EXPRESSLY STATED OTHERWISE IN THIS SECTION, WE MAKE NO EXPRESS
          WARRANTIES OR REPRESENTATIONS AND WE DISCLAIM ALL IMPLIED WARRANTIES
          AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.
        </p>
        <p>
          FURTHERMORE, IF A THIRD PARTY SHALL SURREPTITIOUSLY ENTER OUR WEBSITE
          THROUGH THE USE OF A MEMBER’S LOG-IN OR OTHERWISE, THEY WILL HAVE NO
          RIGHT OF STANDING IN ANY ACTION AGAINST US.
        </p>
      </section>

      <section>
        <h2>Indemnification</h2>
        <p>
          You hereby warrant that You will not use the information provided by
          Us in violation of any International, Local, State, or Federal law.
          Further, You agree to indemnify, defend, and hold Us harmless, as well
          as holding harmless Our parents, subsidiaries, affiliates, officers,
          directors, employees, agents, and suppliers, and their respective
          affiliates, officers, directors, employees, and agents, from any
          claim, action, demand, or damage, including reasonable attorney's
          fees, made by any third party or governmental agency arising out of or
          related to Your use of any service offered by Us or Your violation of
          this Agreement, including without limitation, claims or suits for
          libel, violation of rights of privacy or publicity, interference with
          property rights, trespass, violations of International, Local, Federal
          or State Law, patent infringement or plagiarism. We may, at Our sole
          discretion, assume the exclusive defense and control of any matter
          subject to indemnification by You. The assumption of such defense or
          control by Us, however, shall not excuse any of Your indemnity
          obligations.
        </p>
      </section>

      <section>
        <h2>Force Majeure</h2>
        <p>
          We shall not be liable for delays or nonperformance of this Agreement
          caused by strike, fire, or accidents, nor shall We be liable for delay
          or nonperformance caused by lack of availability of materials, fuel,
          or utilities or for any other cause beyond Our control.
        </p>
      </section>

      <section>
        <h2>Assignment</h2>
        <p>
          You may not assign the rights or obligations under this Agreement.
        </p>
      </section>

      <section>
        <h2>Entire Agreement</h2>
        <p>
          Except as modified or supplemented by a writing executed by both
          parties, the Terms and Conditions described herein are the only
          representations, warranties, and understandings between the parties
          with respect to the products and/or services described herein.
        </p>
      </section>

      <section>
        <h2>Disputes</h2>
        <p>
          In the event of a dispute with Us You agree to attempt to resolve the
          dispute by contacting Us at info@cleansweeps.app and entering into a
          resolution discussion prior to taking any other action. Failure to
          contact Us to attempt a dispute resolution prior to taking any other
          action will result in a breach of this Agreement by You. This
          Agreement and Your use of Our website are governed by the laws of the
          State of Utah, and the courts of general jurisdiction located within
          Salt Lake County, Utah, will have exclusive jurisdiction over any and
          all disputes arising out of, relating to, or concerning this Agreement
          or any other controversy between You and Us. You hereby waive any
          right to a trial by jury in the event of any controversy or claim
          relating to this Agreement. In addition to the foregoing, in the event
          of any breach or violation of this Agreement, We shall be entitled to
          enforce all of Our legal remedies for the breach or wrongful activity
          including, but not limited to, seeking actual damages, the maximum
          amount of statutory damages under applicable statutes and Acts,
          profits, treble damages, and attorneys’ fees and costs. These remedies
          and damages are in addition to the monetary payments described above
          and/or any amounts otherwise due under this Agreement.
        </p>
      </section>

      <section>
        <h2>Severability</h2>
        <p>
          If any provision, or portion thereof, of this Agreement, is held by a
          court of competent jurisdiction to be invalid under any applicable
          statute or rule of law, You and We agree that such invalidity shall
          not affect the validity of the remaining portions of this Agreement
          and further agree to substitute for the invalid provision a valid
          provision which most closely approximates the intent and economic
          effect of the invalid provisions.
        </p>
      </section>

      <section>
        <h2>Headings</h2>
        <p>
          The headings of this Agreement are for convenience only and shall not
          be used to construe the meaning of this Agreement.
        </p>
      </section>

      <section>
        <h2>Agreement Updates</h2>
        <p>
          This Agreement is effective as of October 1, 2024. We reserve the
          right to revise this policy from time to time without prior notice.
          You will be notified of any material changes in Our Terms of Use
          either by email or through a notice on our website.
        </p>
      </section>

      <section>
        <h2>Additional Terms</h2>
        <p>
          Welcome to Clean Sweeps LLC! These Terms of Service ("Terms", "Terms
          of Service") govern your use of our software platform for managing
          sweepstakes ("Service"). By accessing or using our Service, you agree
          to be bound by these Terms. If you disagree with any part of the
          terms, then you may not access the Service.
        </p>
      </section>

      <section>
        <h2>Use of Service</h2>
        <p>
          Our Service allows merchants to manage sweepstakes by serving as the
          record holder, ledger, and source of truth for managing sweepstakes
          entries. You are responsible for ensuring that your use of the Service
          complies with all applicable laws and regulations.
        </p>
      </section>

      <section>
        <h2>User Responsibilities</h2>
        <p>
          You agree to use the Service only for lawful purposes and in
          accordance with these Terms. You are solely responsible for any
          sweepstakes you conduct using our Service, including ensuring the
          authenticity and legality of such sweepstakes.
        </p>
      </section>

      <section>
        <h2>Prohibited Activities</h2>
        <p>
          You agree not to engage in any of the following prohibited activities:
        </p>
        <ul>
          <li>Using the Service for any unlawful purposes.</li>
          <li>Attempting to gain unauthorized access to the Service.</li>
          <li>Interfering with the proper functioning of the Service.</li>
          <li>Distributing malware or other harmful software.</li>
        </ul>
      </section>

      <section>
        <h2>Ownership and Intellectual Property</h2>
        <p>
          All content, features, and functionality of the Service are and will
          remain the exclusive property of Clean Sweeps LLC and its licensors.
          The Service is protected by copyright, trademark, and other laws of
          both the United States and foreign countries.
        </p>
      </section>

      <section>
        <h2>Disclaimer of Warranties</h2>
        <p>
          The Service is provided on an "AS IS" and "AS AVAILABLE" basis. Clean
          Sweeps LLC makes no representations or warranties of any kind, express
          or implied, regarding the operation or availability of the Service, or
          the information, content, or materials included therein.
        </p>
      </section>

      <section>
        <h2>Limitation of Liability</h2>
        <p>
          In no event shall Clean Sweeps LLC, nor its directors, employees,
          partners, agents, suppliers, or affiliates, be liable for any
          indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage.
        </p>
      </section>

      <section>
        <h2>Indemnification</h2>
        <p>
          You agree to defend, indemnify and hold harmless Clean Sweeps LLC and
          its licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Service, by you or any
          person using your account and password; b) a breach of these Terms, or
          c) Content posted on the Service.
        </p>
      </section>

      <section>
        <h2>Termination</h2>
        <p>
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
      </section>

      <section>
        <h2>Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of the State of Arizona, without regard to its conflict of law
          provisions.
        </p>
      </section>

      <section>
        <h2>Changes to Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will provide at
          least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, you are no longer authorized to use the
          Service.
        </p>
      </section>

      <section>
        <h2>Disclaimer of Liability for Sweepstakes Management</h2>

        <p>
          Clean Sweeps LLC ("We", "Us", "Our") provides a software platform that
          serves solely as a record holder, ledger, and source of truth for
          managing sweepstakes entries. We do not own, take ownership of, or
          assume any liability for sweepstakes conducted through our Service.
          Our role is limited to facilitating the management of sweepstakes
          entries.
        </p>

        <h3>Merchant Responsibilities</h3>
        <p>Merchants utilizing our Service are solely responsible for:</p>
        <ul>
          <li>Designing and executing sweepstakes.</li>
          <li>
            Ensuring compliance with all applicable local, state, and federal
            laws and regulations.
          </li>
          <li>Managing prize fulfillment and related obligations.</li>
          <li>
            Maintaining accurate and truthful information in all sweepstakes
            promotions.
          </li>
        </ul>

        <p>
          Furthermore, Clean Sweeps LLC is not liable for any misuse of the
          Service by merchants or any third parties. Merchants agree to
          indemnify, defend, and hold Clean Sweeps LLC harmless from and against
          any and all claims, damages, losses, liabilities, costs, or expenses
          (including reasonable attorney's fees) arising from their misuse of
          the Service.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>
          <strong>Email:</strong> info@cleansweeps.app
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
