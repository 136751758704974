// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer'; // Import the Footer component
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        
        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
        </Routes>

        {/* Add Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
